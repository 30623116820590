@import "~bootstrap/dist/css/bootstrap.css"

body
  background-image: url('assets/images/backgroundx2.webp')
  background-size: 100%
  margin: auto
  height: 100%
  background-repeat: repeat-y

@media (max-width: 1198.98px)
  body
    background-size: auto

::-webkit-scrollbar
    display: none
